<template>
    <section id="bannerHeadHome" class="bg--img--banner--home">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-home-header.jpg')"
            class="white--text "
        >
            <v-container class="fill-height px-4 py-12">
                <v-responsive
                    class="d-flex align-start text-position"
                    height="100%"
                    width="100%"
                >
                    <v-container
                        class="space-title"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100"
                    >
                        <h1 class="title--bann-home title--xl--bann-home text-h6 text-md-h4 font-weight-black">
                            {{ title }}
                        </h1>
                    </v-container>
                    <v-spacer class="gap-spacer home--head--spacer"/>
                    <v-container>
                        <v-layout wrap>
                            <v-flex 
                                d-flex flex-column flex-md-row md-12 xl-6 align-center 
                                class="mb-responsive"
                            >
                                <div class="w--grid--header--home animate__animated animate__pulse animate__delay-2s">
                                    <v-img width="50%" class="mx-auto"
                                    :src="require('@/assets/logos-transparent.png')"
                                    ></v-img>
                                </div>
                                    <div
                                        class="w--grid--header--home"
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                        data-aos-easing="ease-in-sine"
                                    >
                                        <div>
                                            <p
                                                v-for="(text, i) in  texts"
                                                :key="i"
                                                class="text-xl-h6 font-weight-light"
                                            >
                                                {{ text }}
                                            </p>
                                        </div>
                                        <div class="line-after-iso mt-12 primary"></div>
                                        <div>
                                            <v-layout row wrap>
                                                <v-flex 
                                                    d-flex flex-column md12 flex-md-row align-center
                                                    class="block-iso-resp"
                                                >
                                                    <div class="w--grid--header--home">
                                                        <v-img
                                                            :src="require('@/assets/img/logo-iso-90001.png')"
                                                            width="50%"
                                                            class="mx-auto"
                                                        ></v-img>
                                                    </div>
                                                    <div class="w--grid--header--home">
                                                        <p class="cert-txt text-xl-h6 font-weight-light">
                                                            {{ certTxt }}
                                                        </p>
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-responsive>
            </v-container>
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionHomeHeader',
    data() {
        return {
            title: 'Fresado de pavimentos asfálticos y bases granulares',
            texts: [
                'Somos una organización que presta servicios de fresado a nivel nacional, ofreciendo soluciones integrales al cliente, buscando su completa satisfacción y proporcionando una atención que rebase sus requisitos.',
                'Lo anterior con miras a alcanzar la excelencia en nuestro servicio con base en la norma ISO 9001:2000, el cumplimiento de los objetivos de calidad y la mejora continua en todos los procesos de nuestra organización.',
                'Esta sociedad fue constituida en 1997 para la prestación de servicios de ingeniería en general y específicamente para el fresado de pavimentos asfálticos y bases granulares, en la recuperación de autopistas, carreteras, vías urbanas, parqueaderos y aeropuertos.',
            ],
            certTxt: 
                'La entidad certificadora SGS otorgó a FRESAR INGENIEROS la certificación de Gestión de Calidad ISO 9001:2000 para la Prestación del Servicio de Fresado en el Mantenimiento de Pavimentos el día 22 de Mayo de 2007.',
            
        }
    },
}
</script>

<style lang="scss">
#bannerHeadHome {
    .space-title { margin-top: 21%; }
    .line-after-iso { 
        position: relative;
        border-top: 1px solid;
        width: 10%;
    }
    .home--head--spacer { margin: 7vw 0; }
    .text-position {
        .title--bann-home {
            font-size: 2rem;
            width: 90%;
            letter-spacing: 1px;
            text-transform: uppercase;
            line-height: 1.1;
            position: relative;
            padding: 0 24vw 0 1vw;
        }
        .w--grid--header--home {
            width: 50%;
        }
        .cert-txt { font-size: 0.875rem;}
    }

    @media (min-width: 2304px) {
        .text-position {
            .title--bann-home { padding: 3vw 37vw 2vw 2vw; }
            &::before { top: 29%; }
        }
    }

    @media (max-width: 2303px) and (min-width: 1920px) {
        .text-position {
            .title--bann-home { padding: 0 45vw 0 2vw; }
            &::before { top: 29%; }
        }
    }

    @media (max-width: 1680px) and (min-width: 1441px) {
        .text-position {
            .title--bann-home { padding-top: 4vw; }
            &::before { top: 26%; }
        }
    }

    @media (max-width: 1440px) and (min-width: 1025px) {
        .text-position {
            &::before { top: 26%; }
        }
    }

    @media (max-width: 1024px) and (min-width: 801px) {
        .text-position {
            .title--bann-home { padding-right: 9vw; padding-top: 10vw; padding-bottom: 2vw; }
        }
    }

    @media (max-width: 800px) {
        .text-position {
            .title--bann-home { padding: 9vw 8vw 0 4vw; }
            &::before { top: 19%; }
            .w--grid--header--home { width: 90%; margin-top: 2vw; }
        }
    }

    @media (max-width: 600px) {
        .text-position {
            .title--bann-home { padding-top: 16vw; }
        }
    }

    @media (max-width: 500px) and (min-width: 401px) {
        .text-position {
            .title--bann-home { padding-top: 32vw; }
        }
    }
    @media (max-width: 400px) and (min-width: 361px) {
        .text-position {
            .title--bann-home { padding-top: 42vw; }
        }
    }
    @media (max-width: 360px) {
        .text-position {
            .title--bann-home { padding-top: 52vw; }
        }
    }
}
</style>